/* =================================================================

  Cookie

================================================================= */

export const enabledCookie = function () {
  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const getCookie = (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const checkFirstVisit = () => {
    const cookieName = "firstVisit";
    const firstVisitCookie = getCookie(cookieName);
    const referrer = document.referrer;
    const isTopPage = !referrer || referrer.indexOf(window.location.origin) === -1;

    if (isTopPage) {
      if (!firstVisitCookie) {
        // 初回訪問の場合、<html>タグに is-first クラスを付与
        document.documentElement.classList.add("is-first");

        // 1時間後に期限切れとなるCookieをセット
        // setCookie(cookieName, "true", 1 / 24); // 1/24 は1時間
        // setCookie(cookieName, "true", 1 / 1440); // 1/1440 は1分
        setCookie(cookieName, "true", 5 / (24 * 60 * 60)); // 5秒
      }
    }
  };
  checkFirstVisit();

  // const checkFirstVisit = () => {
  //   const cookieName = "firstVisit";
  //   const firstVisitCookie = getCookie(cookieName);

  //   if (!firstVisitCookie) {
  //     // 初回訪問の場合、<html>タグに is-first クラスを付与
  //     document.documentElement.classList.add("is-first");

  //     // 1時間後に期限切れとなるCookieをセット
  //     setCookie(cookieName, "true", 1 / 24); // 1/24 は1時間
  //     // setCookie(cookieName, "true", 1/1440); // 1/1440 は1分
  //   }
  // };
  // checkFirstVisit();
};
