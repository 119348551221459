/* =================================================================

  ページ内リンクをスムーススクロール

================================================================= */

export const smoothScroll = function () {
  window.addEventListener("DOMContentLoaded", () => {
    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
    const header = document.querySelector(".js-header");
    anchorLinksArr.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        const targetId = link.hash;
        const targetElement = document.querySelector(targetId);
        const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
        const targetGap = 0;
        let totalScrollAmount = targetOffsetTop - targetGap;
        if (header) {
          const headerHeight = header.offsetHeight;
          totalScrollAmount = targetOffsetTop - headerHeight - targetGap;
        }
        window.scrollTo({
          top: totalScrollAmount,
          behavior: "smooth",
        });
      });
    });
  });
};
