/* =================================================================

  ドロップダウンメニュー

================================================================= */

export const dropDown = function () {
  document.querySelectorAll(".js-dropdown").forEach((nav) => {
    nav.addEventListener("mouseenter", (e) => {
      e.target.classList.add("is-open");
    });
    nav.addEventListener("mouseleave", (e) => {
      e.target.classList.remove("is-open");
    });
  });
};
