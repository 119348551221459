/* =================================================================

  Modal

================================================================= */
export const modal = function () {
  /* =================================================================

  bodyのスクロールを停止（モーダルウィンドウと連動）

================================================================= */

  //スクロール固定オブジェクトセット
  const bodyScroll = {
    //スクロール位置保存用プロパティ
    scrollY: 0,

    //スクロール固定用ベースstyle
    styles: {
      position: "fixed",
      left: 0,
      width: "100%",
      boxSizing: "border-box",
    },

    //スクロール固定・解除メソッド
    fixed: function (isFixed) {
      //スクロール固定
      if (isFixed) {
        //style追加・更新
        //現在の位置を取得
        this.scrollY = window.pageYOffset || document.documentElement.scrollTop;
        //現在の位置をstyleに設定
        this.styles.top = this.scrollY * -1 + "px";

        //スクロールバーの幅取得・設定（ガタツキ防止）
        this.styles.paddingRight = window.innerWidth - document.body.clientWidth + "px";

        //その他のstyleをセット
        Object.keys(this.styles).forEach(
          function (key) {
            document.body.style[key] = this.styles[key];
          }.bind(this)
        );

        //スクロール固定解除
      } else {
        //styleリセット
        Object.keys(this.styles).forEach(function (key) {
          document.body.style[key] = "";
        });

        //スクロール位置設定
        window.scrollTo(0, this.scrollY);
      }
    },
  };

  /* =================================================================

  モーダルウィンドウ

================================================================= */

  // modalを出す
  const modalOpen = document.querySelectorAll(".js-modal-open");
  const modal = document.querySelectorAll(".js-modal");
  const modalCover = document.querySelectorAll(".js-modal-cover");

  let modalCloseAction;
  let dataModalOpen;
  let targetModal;

  const TIMEOUT_SPEED = 500;

  for (let i = 0; i < modalOpen.length; i++) {
    //モーダルを下げる処理
    modalCloseAction = function (e) {
      targetModal = e.currentTarget.closest(".js-modal");
      targetModal.classList.add("is-close");

      setTimeout(function (e) {
        targetModal.classList.remove("is-open");
        targetModal.classList.remove("is-close");
      }, TIMEOUT_SPEED);
    };

    // グレー部分をクリックでmodalを下げる
    const modalWrapClose = function () {
      modalCover[i].addEventListener(
        "click",
        function (e) {
          modalCloseAction(e);
          bodyScroll.fixed(false); //　スクロール固定解除
        },
        false
      );
    };

    // modalをあげる
    const modalWrapOpen = function (e) {
      dataModalOpen = e.currentTarget.getAttribute("data-modal-open");
      for (let b = 0; b < modal.length; b++) {
        if (modal[b].getAttribute("data-modal") === dataModalOpen) {
          modal[b].classList.add("is-open");
          modalWrapClose();
          return false;
        }
      }
    };

    modalOpen[i].addEventListener(
      "click",
      function (e) {
        modalWrapOpen(e);
        bodyScroll.fixed(true); //　スクロール固定有効
      },
      false
    );
  }

  // modalを下げる
  const modalBtnClose = document.querySelectorAll(".js-modal-close");
  for (let n = 0; n < modalBtnClose.length; n++) {
    modalBtnClose[n].addEventListener(
      "click",
      function (e) {
        modalCloseAction(e);
        bodyScroll.fixed(false); //　スクロール固定解除
        return false;
      },
      false
    );
  }
};
