/* =================================================================

    ページトップボタン

================================================================= */

export const scrollTop = function () {
  //トップへ戻るボタンを取得
  let pagetopBtn = document.querySelector(".js-top-scroll");

  if (!pagetopBtn) {
    return false;
  }

  //　クリックイベントを追加
  pagetopBtn.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  //スクロールした時に処理を実行
  window.addEventListener("scroll", function () {
    //画面上部からトップビジュアル下の位置取得
    // const topVisual = document.querySelector(".p-home-kv").getBoundingClientRect().bottom;

    //トップビジュアル下の位置より下にスクロールされたら is-active classを付与
    if (window.scrollY > 300) {
      pagetopBtn.classList.add("is-active");
    } else {
      //スクロールが300px未満のときactiveクラスを外す
      pagetopBtn.classList.remove("is-active");
    }

    //ドキュメントの高さ
    const scrollHeight = document.body.clientHeight;

    //スクロール位置
    const scrollPosition = window.scrollY;

    //windowの高さ
    const windowHeignt = window.innerHeight;

    // footer取得
    const footer = document.querySelector(".js-footer-fixed");
    // console.log(footer.getBoundingClientRect().top);

    // footerの高さ
    const footerHeight = footer.offsetHeight;
    // console.log(footerHeight);

    // CTA取得
    // const cta = document.querySelector(".js-cta");
    // const ctaHeight = cta.offsetHeight;
    // console.log(ctaHeight);

    if (scrollHeight - scrollPosition - windowHeignt <= footerHeight - 180) {
      pagetopBtn.classList.add("is-stop");
    } else {
      pagetopBtn.classList.remove("is-stop");
    }
  });
};

// export const scrollTop = function () {
//   //ボタン
//   const pagetopBtn = document.querySelector(".js-top-scroll");

//   if (!pagetopBtn) {
//     return false;
//   }

//   //クリックイベントを追加
//   pagetopBtn.addEventListener("click", () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   });

//   //スクロール時のイベントを追加
//   window.addEventListener("scroll", () => {
//     if (window.pageYOffset > 300) {
//       pagetopBtn.style.opacity = "1";
//       pagetopBtn.style.pointerEvents = "all";
//     } else if (window.pageYOffset < 300) {
//       pagetopBtn.style.opacity = "0";
//       pagetopBtn.style.pointerEvents = "none";
//     }

//     // const scrollHeight = document.body.clientHeight;
//     // const scrollPosition = window.innerHeight + window.pageYOffset;
//     // const footHeight = document.getElementsByClassName("l-footer")[0].clientHeight;

//     // console.log(footHeight);

//     // if (scrollHeight - scrollPosition <= footHeight) {
//     //   pagetopBtn.style.position = "absolute";
//     //   pagetopBtn.style.bottom = footHeight + 30 + "px";
//     // } else {
//     //   pagetopBtn.style.position = "fixed";
//     //   pagetopBtn.style.bottom = 30 + "px";
//     // }
//   });
// };
