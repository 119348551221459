/* =================================================================

  カレント表示

================================================================= */

export const currentMenu = function () {
  let navLink = document.querySelectorAll(".js-current-menu > li > a");
  for (let i = 0; i < navLink.length; i++) {
    if (navLink[i].href === location.href) {
      navLink[i].classList.add("is-current");
    }
  }
};
