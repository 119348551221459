// スムーススクロール
import { smoothScroll } from "./lib/smooth-scroll";
smoothScroll();
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

// スクロールトップ
import { scrollTop } from "./lib/scroll-top";
scrollTop();

// インビュー
import { inview } from "./lib/inview";
inview();

// ドロワーメニュー
import { drawer } from "./lib/drawer";
drawer();

// ドロップダウン
import { dropDown } from "./lib/dropdown";
dropDown();

// モーダルウィンドウ
import { modal } from "./lib/modal";
modal();

// モーダルウィンドウ(プロフィール)
// import { modalProfile } from "./lib/modal-profile";
// modalProfile();

// アコーディオン
import { accordion } from "./lib/accordion";
accordion();

// カレント表示
import { currentMenu } from "./lib/current-menu";
currentMenu();

// クッキー使用
import { enabledCookie } from "./lib/cookie";
enabledCookie();

// Online Shop Button 追従
// import { onlineshopButtonFixed } from "./lib/online-shop-button";
// onlineshopButtonFixed();

/* =================================================================

  jsが使用可能の場合classを付与

================================================================= */

document.documentElement.classList.add("is-jsEnable");

/* =================================================================

  右クリック禁止

================================================================= */

// document.oncontextmenu = function () {
//   return false;
// };

/* =================================================================

  画面幅からはみ出た要素をJavaScriptで検出する

================================================================= */

// document.querySelectorAll("*").forEach((el) => (el.clientWidth > document.body.clientWidth ? console.log(el) : null));

/* =================================================================

  読み込みが完了したらclassを付与

================================================================= */

const load = function () {
  window.addEventListener("load", () => {
    document.documentElement.classList.add("is-loaded");
    setTimeout(function () {
      document.documentElement.classList.add("is-start");
      // setTimeout(function () {
      //   document.documentElement.classList.add("is-over");
      // }, 1000);
    }, 3000);
  });
};
load();

/* =================================================================

  スクロールバーの横幅を計算

================================================================= */

const setScrollbarWidth = () => {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  // カスタムプロパティの値を更新する
  document.documentElement.style.setProperty("--scrollbar", `${scrollbarWidth}px`);
};

// 表示されたとき
window.addEventListener("load", setScrollbarWidth);
// リサイズしたとき
window.addEventListener("resize", setScrollbarWidth);

/* =================================================================

    header変形

================================================================= */

//スクロール時のイベントを追加

const handleHeaderAnimation = () => {
  const header = document.querySelector(".js-header");
  const hero = document.querySelector(".js-hero");

  // header または hero が存在しない場合は処理を中断
  if (!header || !hero) return;

  const heroBottom = hero.offsetTop + hero.offsetHeight;
  header.classList.toggle("is-animation", window.scrollY > heroBottom);
};

window.addEventListener("scroll", handleHeaderAnimation);
window.addEventListener("resize", handleHeaderAnimation);

// window.addEventListener("scroll", () => {
//   const headerAnimation = document.querySelector(".js-header");
//   if (headerAnimation) {
//     if (window.scrollY > 100) {
//       headerAnimation.classList.add("is-animation");
//     } else if (window.scrollY < 1) {
//       headerAnimation.classList.remove("is-animation");
//     }
//   }
// });

/* =================================================================

  別ページからのリンク

================================================================= */

window.addEventListener("load", () => {
  const header = document.querySelector(".js-header");

  // header が存在しない場合は処理を中断
  if (!header) return;

  const headerHeight = header.clientHeight;
  const urlHash = location.hash;

  if (urlHash) {
    const target = document.querySelector(urlHash);

    // target が存在しない場合は処理を中断
    if (!target) return;

    const targetTop = target.getBoundingClientRect().top + window.pageYOffset;
    const position = targetTop - headerHeight;

    window.scrollTo({
      top: position,
      // behavior: "smooth",
    });
  }
}); /* =================================================================

  フォーム

================================================================= */

// 確認画面から戻る際に値を保持する
const formBack = function () {
  const backButton = document.querySelector(".js-back");

  if (backButton) {
    backButton.addEventListener("click", () => {
      const formElement = document.getElementById("form");
      formElement.setAttribute("action", formElement.getAttribute("action").replace("complete", "form#form"));

      // input[mode] を書き換え
      document.querySelector("input[name=mode]").setAttribute("value", "");
    });
  }
};
formBack();

// セレクトボックスの初期値
function updateSelectOptions() {
  var options = document.querySelectorAll('.mw_wp_form_input select option[value=""]');
  options.forEach(function (option) {
    option.innerHTML = "選択してください";
  });
}
document.addEventListener("DOMContentLoaded", updateSelectOptions);

/* =================================================================

  サイドメニュー高さ取得

================================================================= */

// const myFunc = () => {
//   let elem = document.querySelector(".js-side");
//   let sideHeight = elem.offsetHeight;
//   elem.style.height = sideHeight + "px";
// };

// window.addEventListener("load", myFunc, true);
// window.addEventListener("resize", myFunc, true);

/* =================================================================

  「個人情報保護方針に同意する」にチェックで有効

================================================================= */

function checkFlag() {
  const enabled = document.querySelector(".js-submit-button");
  const consent = document.querySelector(".js-consent");

  if (enabled || consent) {
    if (consent.checked) {
      enabled.classList.add("is-enabled");
    }
    consent.addEventListener("click", () => {
      if (consent.checked) {
        enabled.classList.add("is-enabled");
      } else {
        enabled.classList.remove("is-enabled");
      }
    });
  }
}
checkFlag();

/* =================================================================

  コンテンツをもっと見る・閉じるで開閉

================================================================= */
const readmore = document.querySelectorAll(".js-readMoreInner");

Array.from(readmore).forEach(function (more) {
  const btn = more.querySelector(".js-readMoreBtn");
  const btnText = more.querySelector(".js-readMoreBtn .text");
  const content = more.querySelector(".js-readMoreContainer");

  // ボタンクリックでイベント処理
  btn.addEventListener("click", () => {
    if (!content.classList.contains("is-show")) {
      content.style.maxHeight = content.scrollHeight + "px";
      content.classList.add("is-show");
      btn.classList.add("is-show");
      btnText.innerText = "閉じる";
    } else {
      content.style.maxHeight = "400px";
      content.classList.remove("is-show");
      btn.classList.remove("is-show");
      btnText.innerText = "もっと見る";
    }
  });
});
/* =================================================================

  venobox

================================================================= */
import VenoBox from "venobox";
new VenoBox({
  selector: ".js-venobox",
  border: "20px",
  spinner: "bounce",
  spinColor: "#968B7C",
  overlayColor: "rgba(0,0,0,0.6)",
  arrowsColor: "#968B7C",
  infinigall: true,
});

/* =================================================================

  ScrollHint

================================================================= */

import ScrollHint from "scroll-hint";
new ScrollHint(".js-scrollable", {
  // scrollHintIconAppendClass: "scroll-hint-icon-white", // white-icon will appear
  i18n: {
    scrollable: "スクロールできます",
  },
});
/* =================================================================

  swiper

================================================================= */
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
import { useCookie } from "./lib/cookie";

// import styles bundle
// import "swiper/css/bundle";

const kvSlider = new Swiper(".js-kv-slider", {
  effect: "fade",
  speed: 2000,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  autoplayDisableOnInteraction: false,
  loop: true,
  slidesPerView: "auto",
  loopAdditionalSlides: 1,
  centeredSlides: false,
  slideToClickedSlide: true,
  spaceBetween: 0,
});

kvSlider.on("slideChange", function () {
  const parentElement = document.querySelector(".js-home-hero-catch");
  if (kvSlider.realIndex === 1) {
    // 2枚目のスライド（インデックスは0から始まる）
    parentElement.classList.add("is-active-slide-02");
  } else {
    parentElement.classList.remove("is-active-slide-02");
  }
});

// const carouselSlider = new Swiper(".js-carousel-slider", {
//   effect: "slider",
//   speed: 500,
//   autoplay: {
//     delay: 4000,
//     disableOnInteraction: false,
//     // reverseDirection: false,
//   },
//   autoplayDisableOnInteraction: false,
//   loop: true,
//   centeredSlides: true,
//   slidesPerView: 1.4,
//   spaceBetween: 24,
//   allowTouchMove: true,
//   slideToClickedSlide: true,
//   loopAdditionalSlides: 1,
//   // slideToClickedSlide: true,
//   breakpoints: {
//     768: {
//       spaceBetween: 40,
//     },
//     1200: {
//       spaceBetween: 100,
//     },
//   },
// });

// const mySwiper = new Swiper(".js-slider", {
//   effect: "slider",
//   speed: 500,
//   autoplay: {
//     delay: 5000,
//     disableOnInteraction: false,
//   },
//   autoplayDisableOnInteraction: false,
//   loop: true,
//   slidesPerView: 1.4,
//   loopedSlides: 1,
//   centeredSlides: true,
//   slideToClickedSlide: true,
//   spaceBetween: 100,
//   // breakpoints: {
//   //   768: {
//   //     slidesPerView: 2,
//   //     spaceBetween: 24,
//   //     centeredSlides: false,
//   //   },
//   //   1200: {
//   //     slidesPerView: 3,
//   //     spaceBetween: 24,
//   //     centeredSlides: false,
//   //   },
//   //   1680: {
//   //     slidesPerView: 3,
//   //     spaceBetween: 24,
//   //     centeredSlides: false,
//   //   },
//   // },

//   // pagination: {
//   //   el: ".js-swiper-pagination",
//   //   type: "bullets",
//   //   clickable: true,
//   //   bulletElement: "div",
//   // },
//   // navigation: {
//   //   nextEl: ".js-swiper-button-next",
//   //   prevEl: ".js-swiper-button-prev",
//   // },
// });
