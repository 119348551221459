/* =================================================================

  ドロワーメニュー

================================================================= */

export const drawer = function () {
  const elem = document.querySelector(".js-drawer");

  // elem が存在しない場合は処理を中断
  if (!elem) {
    return;
  }

  const closeButton = document.querySelector(".js-drawer-close");

  elem.addEventListener("click", function (e) {
    const isExpanded = e.currentTarget.getAttribute("aria-expanded") !== "false";
    e.currentTarget.setAttribute("aria-expanded", !isExpanded);

    document.documentElement.classList.toggle(`is-drawerActive`);

    //　ハンバーガーボタンの　Menu　のテキストを書き換え
    // const output = document.querySelector(".js-hamburgerText");
    // if (document.documentElement.classList.contains("is-drawerActive") == true) {
    //   output.innerHTML = "Close";
    // } else {
    //   output.innerHTML = "Menu";
    // }
  });

  const nav = document.querySelectorAll("#drawer a[href^='#']");
  for (let i = 0; i < nav.length; i++) {
    nav[i].addEventListener("click", function (e) {
      document.documentElement.classList.remove(`is-drawerActive`);
      elem.setAttribute("aria-expanded", false);
    });
  }

  // closeButton が存在する場合のみイベントリスナーを追加
  if (closeButton) {
    closeButton.addEventListener("click", function (e) {
      document.documentElement.classList.remove(`is-drawerActive`);
      elem.setAttribute("aria-expanded", false);
    });
  }
};
